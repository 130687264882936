import { css } from '@emotion/core';

import { layout } from './layout.styles';
import { color } from './mixins.styles';
import { mqFrom, mqTo } from './responsive.styles';
import { typescale } from './typescale.styles';
import { fonts } from './variables';
import { colors, fontWeights, unit } from './vars.styles';

const root = css`
  //padding: ${unit * 6}px 0;
  height: 0;

  ${mqFrom.Med} {
    border-bottom: 1px solid ${color('#000000', 0.1)};
  }
`;

const headerNone = css`
  display: none;
`;

const wrapper = css`
  ${layout.wrapper};

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 89px;
  width: 100%;
`;

const logo = css`
  height: 30px;
  transition: height 0.3s;
  image-rendering: -webkit-optimize-contrast;

  ${mqFrom.XSml} {
    height: 32px;
  }

  ${mqFrom.Sml} {
    height: 35px;
  }

  ${mqFrom.Med} {
    height: 38px;
  }

  ${mqFrom.Lrg} {
    height: 48px;
  }
`;

const center = css`
  display: flex;
  align-items: center;
`;

const nav = css`
  display: flex;
  align-items: center;

  ${mqTo.Lrg} {
    display: none;
  }
`;

const navLink = css`
  position: relative;
  padding: ${unit * 6}px ${unit * 8}px;
  height: 100%;
  cursor: pointer;
  margin-top: 16px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 2px;
    background: ${colors.textSecondary};
    opacity: 0.6;
    transition: width 0.3s;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

const navLinkOpened = css`
  &:after {
    width: 100%;
  }
`;

const navLinkActive = css`
  &:after {
    width: 100%;
    background: ${colors.primary};
  }
`;

const navOpen = css`
  position: fixed;
  cursor: pointer;
  border: 0;
  touch-action: manipulation;
  height: 30px;
  width: 30px;

  background-color: ${colors.bg};

  top: 33px;
  right: ${unit * 12}px;

  border-radius: 50%;

  ${mqFrom.Xlrg} {
    display: none;
  }

  &:focus {
    outline: 0;
  }
`;

const title = css`
  text-transform: uppercase;
  white-space: nowrap;

  font-family: ${fonts.sansSerif};
  font-weight: ${fontWeights.weightBold};
  font-style: normal;
  ${typescale.small};

  color: ${colors.textSecondary};
`;

const lineDown = css`
  transform: rotate(-45deg) translate(-3px, 3px);
`;

const lineUp = css`
  transform: rotate(45deg) translate(-2px, -3px);
`;

const lineDissapear = css`
  opacity: 0;
`;

const hamburger = css`
  width: 100%;
  height: 2px;
  background-color: ${colors.textSecondary};
  margin: 2px 0;
  transition: all 0.3s ease-out;
`;

const navSmall = css`
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

const visibleHeader = css`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 0 ${unit * 10}px;

  ${mqTo.Lrg} {
    display: none;
  }
`;

const hidden = css`
  display: none;
`;

const visibleSearch = css`
  display: inline;
  width: 100%;

  justify-content: center;
  align-items: center;
  padding: 0 ${unit * 10}px;
`;

const searchWrapper = css`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${colors.primary};
`;

const scene = css`
  position: fixed;
  top: 0;
  background-color: ${colors.bg};
  border-bottom: 1px solid ${color('#000000', 0.1)};
  z-index: 1;
  width: 100%;
  height: 89px;
`;

const headerWrapper = (isOpened: boolean) => css`
  height: 89px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  transition: transform 0.5s;

  ${isOpened &&
  `transform-style: preserve-3d;
  transform: translateZ(-100px);`}
`;

const headerFace = css`
  position: absolute;
  ${layout.wrapper};
  width: 100%;
  height: 89px;
  transition: all 0.5s;

  display: flex;
  align-items: center;

  ${mqTo.Lrg} {
    justify-content: space-between;
  }
`;

const headerFront = (isActive: boolean) => css`
  ${headerFace};
  transform: rotateX(-90deg) translateZ(100px);
  opacity: 0;

  ${isActive &&
  `transform: translateZ(100px) rotateY(0deg);
  opacity: 1;`};
`;

const headerTop = (isActive: boolean) => css`
  ${headerFace};
  transform: rotateX(90deg) translateZ(100px);
  opacity: 0;

  ${isActive &&
  `transform: translateZ(100px) rotateX(0deg);
  opacity: 1;
  `};
`;

const onModalOpen = css`
  pointer-events: none;
`;

const unClickableHeader = css`
  pointer-events: none;
  cursor: none;
  z-index: -1;
`;

const navHeight = css`
  height: 0;
`;

export const headerStyles = {
  root,
  headerNone,
  wrapper,
  logo,
  nav,
  navSmall,
  title,
  navLink,
  navOpen,
  hamburger,
  lineUp,
  lineDissapear,
  lineDown,
  center,
  visibleHeader,
  visibleSearch,
  hidden,
  navLinkActive,
  navLinkOpened,
  searchWrapper,
  scene,
  headerWrapper,
  headerFront,
  headerTop,
  onModalOpen,
  unClickableHeader,
  navHeight,
};
